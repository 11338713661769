import { useCallback, useMemo } from 'react'

import patterns from '../patterns'
import { PortfolioStoryblok, SiteStoryblok } from '../storyblok.generated'
import { StoryblokStoryResponse, StoryblokStory } from '../../utils/storyblok'
import { useStory } from '../../utils/useStory'
import BlokZone from '../../components/BlokZone'
import Head from '../../components/Head'
import createSeoHeadParams from '../../utils/createSeoHeadParams'
import useIsClientSideRender from '../../utils/useIsClientSideRender'
import SectionIndicatorClipped from '../../components/SectionIndicatorClipped'
import FooterBlok from '../FooterBlok'
import HeaderBlok from '../HeaderBlok'
import { PageContext, PageContextProvider } from '../PageContext'
import convertBloksToPatterns from '../convertBloksToPatterns'
import { useCurrentHostname } from '../../utils/getHostNameForSite'

interface Props {
  siteStory: StoryblokStoryResponse<StoryblokStory<SiteStoryblok>>
  story: StoryblokStoryResponse<StoryblokStory<PortfolioStoryblok>>
  slug: string
}

const PortfolioPage = ({ story: pageStory, siteStory, slug }: Props) => {
  const page = useStory(pageStory.story)
  const site = useStory(siteStory.story)
  const { hostName } = useCurrentHostname()

  const bloks = page.content.content

  const isClientSideRender = useIsClientSideRender()

  // Compose the SectionIndicator so we can pass a simpler component to children
  const SectionIndicator = useCallback(
    ({ variant }: { variant: 'light' | 'dark' }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks, react-hooks/exhaustive-deps
      const patterns = useMemo(() => convertBloksToPatterns(bloks), [bloks])
      return <SectionIndicatorClipped variant={variant} patterns={patterns} />
    },
    [bloks],
  )

  const context: PageContext = useMemo(() => {
    // Why do we do this?? Wouldn't it be cleaner to just pass all rels? What
    // harm is there in passing the current page? Is this to reduce the data
    // size sent to the client?
    const otherRels = pageStory.rels.filter(
      (rel: StoryblokStory) => rel !== page,
    )

    return {
      rels: otherRels,
      links: pageStory.links,
      siteStory,
      SectionIndicator: isClientSideRender ? SectionIndicator : undefined,
    }
  }, [pageStory, page, isClientSideRender, SectionIndicator, siteStory])

  return (
    <PageContextProvider value={context}>
      <Head {...createSeoHeadParams(page.content, slug, hostName)} />

      <HeaderBlok siteStory={site.content} variant="light" />

      <BlokZone components={patterns} content={bloks} {...context} />

      <FooterBlok content={site.content.footerBlock[0]} />
    </PageContextProvider>
  )
}

export default PortfolioPage
