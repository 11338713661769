import { GetStaticProps } from 'next'

import stripUndefined from '../../utils/stripUndefined'
import {
  PortfolioStoryblok,
  SiteStoryblok,
} from '../../bloks/storyblok.generated'
import {
  getPortfolioStory,
  getSiteStory,
  StoryblokStoryResponse,
  StoryblokStory,
  getStoryblokSpace,
  defaultResolveRelations,
} from '../../utils/storyblok'
import { StoryblokPreviewData } from '../../storyblokPreview'
import PortfolioPage from '../../bloks/pages/PortfolioPage'
import SWRProvider from '../../components/SWRProvider'
import { fetchImageSequence } from '../api/image-sequence'
import { getPublicRuntimeConfig } from '../../utils/getPublicRuntimeConfig'

export interface StaticProps {
  siteStory: StoryblokStoryResponse<StoryblokStory<SiteStoryblok>>
  pageStory: StoryblokStoryResponse<StoryblokStory<PortfolioStoryblok>>
  fallback: Record<string, unknown>
  slug: string
}

const baseSlug = 'portfolio'

export const getStaticPaths = async () => {
  return {
    // TODO
    paths: [],
    fallback: 'blocking',
  }
}

const preloadSliceData = async (slices: PortfolioStoryblok['content']) => {
  const fallback: Record<string, unknown> = {}
  await Promise.all(
    slices.map(async (slice) => {
      switch (slice.component) {
        case 'portfolioHeader':
          {
            const tag = slice.scrollImageSequenceTag
            if (!tag) {
              return
            }

            const data = await fetchImageSequence(tag)

            fallback[`/api/image-sequence?tag=${tag}`] = data
          }

          break
        default:
          break
      }
    }),
  )
  return fallback
}

export const getStaticProps: GetStaticProps<
  StaticProps,
  { slugs: string | string[] },
  StoryblokPreviewData
> = async ({ preview, previewData, params, locale }) => {
  if (!locale) {
    throw new Error('Next.js is misconfigured: locale is missing')
  }

  const storyblokFetchOptions = {
    version:
      (process.env.STORYBLOK_VERSION as 'draft' | 'published' | undefined) ||
      (preview ? 'draft' : 'published'),
    fromRelease: previewData?.release,
    cacheVersion:
      previewData?.timestamp ?? (await getStoryblokSpace()).space?.version,
    resolveRelations: [
      // It's not very pretty to preload this data this way as there's no
      // reference from the form blok component, but it's the
      // simplest effective solution by a mile.
      ...defaultResolveRelations,
    ],
  }

  const siteStory = await getSiteStory(locale, storyblokFetchOptions)
  if (!siteStory) {
    throw new Error('Site is misconfigured: site story is missing from CMS')
  }

  const slugPath = Array.isArray(params?.slugs)
    ? params?.slugs?.join('/').toLowerCase()
    : params?.slugs?.toLowerCase()
  if (!slugPath) {
    return {
      notFound: true,
      revalidate: 60,
    }
  }
  const slug = `${baseSlug}/${slugPath}`

  const pageStory = await getPortfolioStory(slug, locale, storyblokFetchOptions)

  if (!pageStory || pageStory.story.content.component !== 'portfolio') {
    return {
      notFound: true,
      props: {
        ...getPublicRuntimeConfig(),
      },
      // TODO: Configure via environment variables
      revalidate: 60,
    }
  }

  const fallback = await preloadSliceData(pageStory.story.content.content)

  return {
    props: stripUndefined({
      preview,
      siteStory,
      pageStory,
      // TODO: Evaluate if we should keep this
      fallback,
      slug,
      ...getPublicRuntimeConfig(),
    }),
    // TODO: Configure via environment variables
    revalidate: 60,
  }
}

const PageResolver = ({ pageStory, fallback, ...others }: StaticProps) => (
  <SWRProvider fallback={fallback}>
    <PortfolioPage story={pageStory} {...others} />
  </SWRProvider>
)

export default PageResolver
